//== Colors
$primary-color: #272727;
$highlight-color: #de9801;
$headings-color: $highlight-color;
$button-color: $highlight-color;
$gray-base: #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 70%); // #ccc
$gray-lightest:           lighten($gray-base, 90%); // #ccc
$red: #CD211D;

//== Typography
$font-size-base:          14px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)); // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)); // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.85)); // ~12px

$button-border-radius: 8px;