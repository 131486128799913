a.u { text-decoration: underline; }
.b { font-weight: bold; }
.btn-box { cursor: pointer; }
.cb, .clear { clear:both; }
.cl { clear: left; }
.cr { clear: right; }
.db { display: block; }
.l, .left {	float:left; }
.r, .right { float:right; }
.right, .r { float:right; }
.top { vertical-align:top; }
.c, .center { margin:0 auto; }
.top { vertical-align:top; }
.t-left { text-align:left; }
.t-right { text-align:right; }
.t-center { text-align:center; }
.disabled { cursor:default; }
.h, .hide, .hidden { display: none !important; visibility: hidden; }
.invisible { visibility: hidden; }
.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; }
.ir br { display: none; }
.bn { border: none; }
.mt { margin-top: 10px; }
.mb { margin-bottom: 10px; }
.ml { margin-left: 10px; }
.mr { margin-right: 10px; }
.mt2 { margin-top: 20px; }
.mb2 { margin-bottom: 20px; }
.ml2 { margin-left: 20px; }
.mr2 { margin-right: 20px; }
.nm { margin: 0; }
.pa { position: absolute; }
.pr { position: relative; }
.raw-box { margin: 0; padding: 0; border: none; }